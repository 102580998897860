import * as moment from 'moment';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { Component,  EventEmitter,  Inject,  Input,  OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from 'src/app/core/services/dashboard.service';

export interface CommissionElement {
  supplierConfig: any;
  airlines: any;
  airlineCommissionId: number;
  airlineCode: string;
  supplierConfigId: number;
  cabinClass: string;
  travelType: string;
  bookingCodeAllowedList: string;
  bookingCodeDisallowedList: string;
  fromRouteType: string;
  toRouteType: string;
  fromCountryAllowedList: string;
  fromCountryDisallowedList: string;
  fromAirportAllowedList: string;
  fromAirportDisallowedList: string;
  toCountryAllowedList: string;
  toCountryDisallowedList: string;
  ticketingActiveDate: string;
  ticketingExpireDate: string;
  blackoutStartDate: string;
  blackoutEndDate: string;
  travelToDate: string;
  commissionAmount: string;
  currency: string;
  pointOfSale: string;
  pointOfOrigin: string;
  activeFlag: string;
  notes: string;
}

@Component({
  selector: 'airline-commission',
  templateUrl: './airline-commission.component.html',
  styleUrls: ['./airline-commission.component.scss']
})
export class AirlineCommissionComponent implements OnInit {
  constructor(private dashboardService: DashboardService, private _liveAnnouncer: LiveAnnouncer, private dialog: MatDialog) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public displayedColumns: string[] = [
    'airlineCommissionId',
    'airlineCode',
    'supplierName',
    'cabinClass',
    'travelType',
    'bookingCodeAllowedList',
    'bookingCodeDisallowedList',
    'fromRouteType',
    'toRouteType',
    'fromCountryAllowedList',
    'fromCountryDisallowedList',
    'fromAirportAllowedList',
    'fromAirportDisallowedList',
    'toCountryAllowedList',
    'toCountryDisallowedList',
    'ticketingActiveDate',
    'ticketingExpireDate',
    'blackoutStartDate',
    'blackoutEndDate',
    'travelToDate',
    // 'currency',
    'commissionAmount',
    'pointOfSale',
    'pointOfOrigin',
    'activeFlag',
    'notes',
    'edit'
  ];
  public searchControl: FormControl = new FormControl();
  public dataSource = new MatTableDataSource<any>([]);
  public loading: boolean = true;
  public airlineCommissionList: any[] = [];
  public showBookingDetails: boolean = false;
  public bookingDetails: any = {};
  public supplierConfigs = [];
  public airlines = [];

  ngOnInit(): void {
    this.getAllairlineCommissionList();
  }

  public getAllairlineCommissionList(): void {
    this.dashboardService.getAllAirlineCommission().subscribe((res: any) => {
      this.supplierConfigs = res.data.supplierConfig;
      this.airlines = res.data.airlines;
      this.airlineCommissionList = res.data.allCommission.map((d: any) => {
        return {
          supplierName: d.supplierConfig.supplierName,
          ...d,
        }
      });
      this.dataSource = new MatTableDataSource<any>(this.airlineCommissionList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    },
    (err: any) => {
      this.loading = false;
    });
  }

  public searchItem(): void {
    this.searchControl.setValue(this.searchControl.value.trim())
    const query = this.searchControl.value.toLowerCase();
    const result = this.airlineCommissionList.filter((data: any) => {
      return data.airlineCommissionId?.toString().search(query) !== -1 || data.airlineCode?.toLowerCase().search(query) !== -1;
    });
    this.dataSource = new MatTableDataSource<any>(result);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public resetSearch(event: string): void {
    if (!event) {
      const result = this.airlineCommissionList.filter((data: any) => {
        return data.airlineCommissionId.toString().toLowerCase().search('') !== -1 || data.airlineCode.toLowerCase().search('') !== -1;
      });
      this.dataSource = new MatTableDataSource<any>(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  public getMomentDate(date: string): string {
    return moment(date).format('MMMM DD, YYYY');
  }

  public getMomentDateTime(date: string): string {
    return moment(date).format('MMM DD, YYYY hh:mm A');
  }

  public announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  public upsertCommissionPopup(data: any = {}): void {
    data.supplierConfig = this.supplierConfigs;
    data.airlines = this.airlines;
    const dialogRef = this.dialog.open(EditCommissionComponent, {
      width: '50vw',
      height: '150vw',
      autoFocus: false,
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.getAllairlineCommissionList();
      }
    });
  }
}
@Component({
  selector: 'app-edit-commission',
  templateUrl: './edit-commission.component.html',
  styleUrls: ['./edit-commission.component.scss']
})
export class EditCommissionComponent implements OnInit {
  constructor(
    private dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: CommissionElement,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EditCommissionComponent>
  ) {}


  public supplierConfigs = this.data.supplierConfig || [];
  public submitButtonText = this.data.airlineCommissionId ? 'Update' : 'Add';

  public airlinesArray = this.data.airlines || [];
  public filteredAirlines = [...this.airlinesArray];
  public searchAirline: string = '';
  public selectedAirline: any = null;
  public formSubmit: boolean = false;

  public cabinClasses = [
    {
      name: 'Economy',
      value: 'Y'
    },
    {
      name: 'Premium Economy',
      value: 'W'
    },
    {
      name: 'Business',
      value: 'C'
    },
    {
      name: 'First',
      value: 'F'
    }
  ];

  public travelTypes = [
    {
      name: 'One-way',
      value: 'oneway'
    },
    {
      name: 'Round trip',
      value: 'roundtrip'
    },
  ];

  public activeFlags = [
    {
      name: 'Yes',
      value: 'Y'
    },
    {
      name: 'No',
      value: 'N'
    },
  ];

  public currencies = [
    {
      name: 'USD',
      value: 'USD'
    },
    {
      name: 'INR',
      value: 'INR'
    },
  ];

  public supplierConfigId: number = this.data.supplierConfigId || 0;

  public form = this.formBuilder.group({
    airlineCommissionId: [null],
    airlineCode: [null],
    supplierConfigId: [null, Validators.required],
    cabinClass: [null, Validators.required],
    travelType: [null, Validators.required],
    bookingCodeAllowedList: [null],
    bookingCodeDisallowedList: [null],
    fromRouteType: [null],
    toRouteType: [null],
    fromCountryAllowedList: [null],
    fromCountryDisallowedList: [null],
    fromAirportAllowedList: [null],
    fromAirportDisallowedList: [null],
    toCountryAllowedList: [null],
    toCountryDisallowedList: [null],
    ticketingActiveDate: [null],
    ticketingExpireDate: [null],
    blackoutStartDate: [null],
    blackoutEndDate: [null],
    travelToDate: [null],
    commissionAmount: [null, Validators.required],
    currency: [null],
    pointOfSale: [null],
    pointOfOrigin: [null],
    activeFlag: [null],
    notes: [null],
  });

  public fromDate: FormControl = new FormControl(null);
  public toDate: FormControl = new FormControl(null);

  ngOnInit(): void {
    if (this.data.airlineCommissionId) {
      this.form.patchValue({
        airlineCommissionId: this.data.airlineCommissionId,
        airlineCode: this.data.airlineCode,
        supplierConfigId: this.supplierConfigId,
        cabinClass: this.data.cabinClass,
        travelType: this.data.travelType,
        bookingCodeAllowedList: this.data.bookingCodeAllowedList,
        bookingCodeDisallowedList: this.data.bookingCodeDisallowedList,
        fromRouteType: this.data.fromRouteType,
        toRouteType: this.data.toRouteType,
        fromCountryAllowedList: this.data.fromCountryAllowedList,
        fromCountryDisallowedList: this.data.fromCountryDisallowedList,
        fromAirportAllowedList: this.data.fromAirportAllowedList,
        fromAirportDisallowedList: this.data.fromAirportDisallowedList,
        toCountryAllowedList: this.data.toCountryAllowedList,
        toCountryDisallowedList: this.data.toCountryDisallowedList,
        ticketingActiveDate: moment(new Date(this.data.ticketingActiveDate).toISOString().split('T')[0]),
        ticketingExpireDate: moment(new Date(this.data.ticketingExpireDate).toISOString().split('T')[0]),
        blackoutStartDate: moment(new Date(this.data.blackoutStartDate).toISOString().split('T')[0]),
        blackoutEndDate: moment(new Date(this.data.blackoutEndDate).toISOString().split('T')[0]),
        travelToDate: moment(new Date(this.data.travelToDate).toISOString().split('T')[0]),
        commissionAmount: this.data.commissionAmount,
        currency: this.data.currency,
        pointOfSale: this.data.pointOfSale,
        pointOfOrigin: this.data.pointOfOrigin,
        activeFlag: this.data.activeFlag,
        notes: this.data.notes
      });
      this.selectedAirline = this.data.airlineCode;
    } else {
      this.form.patchValue({
        supplierConfigId: this.supplierConfigs[0].supplierConfigId,
        cabinClass: this.cabinClasses[0].value,
        travelType: this.travelTypes[0].value,
        ticketingActiveDate: moment(new Date().toISOString().split('T')[0]),
        ticketingExpireDate: moment(new Date().toISOString().split('T')[0]),
        blackoutStartDate: moment(new Date().toISOString().split('T')[0]),
        blackoutEndDate: moment(new Date().toISOString().split('T')[0]),
        travelToDate: moment(new Date().toISOString().split('T')[0]),
        currency: this.currencies[0].value,
        activeFlag: this.activeFlags[0].value,
      });
    }
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public submit(): void {
    this.formSubmit = true;
    if (this.form.invalid || !this.selectedAirline) {
      return;
    }
    const form = {
      ...this.form.value,
      airlineCode: this.selectedAirline
    };
    this.dashboardService.updateAirlineCommission(form).subscribe(
      (res: any) => {
        this.dialogRef.close(true);
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

   public calendarChange(event: any, type: string): void {
    if (event.startDate) {
      if (type === 'ticketingActiveDate') {
        this.form.get('ticketingActiveDate')!.setValue(moment(event.startDate).format('YYYY-MM-DD'));
      } else if (type === 'ticketingExpireDate') {
        this.form.get('ticketingExpireDate')!.setValue(moment(event.startDate).format('YYYY-MM-DD'));
      } else if (type === 'blackoutStartDate') {
        this.form.get('blackoutStartDate')!.setValue(moment(event.startDate).format('YYYY-MM-DD'));
      } else if (type === 'blackoutEndDate') {
        this.form.get('blackoutEndDate')!.setValue(moment(event.startDate).format('YYYY-MM-DD'));
      } else if (type === 'travelToDate') {
        this.form.get('travelToDate')!.setValue(moment(event.startDate).format('YYYY-MM-DD'));
      }
    }
  }

  public filterairlines() {
    if (!this.searchAirline) {
      this.filteredAirlines = [...this.airlinesArray];
    } else {
      const lowerSearchText = this.searchAirline.toLowerCase();
      this.filteredAirlines = this.airlinesArray.filter((item: { IATACode: string; AirlineNameShort: string; }) =>
        item.IATACode.toLowerCase().includes(lowerSearchText) || item.AirlineNameShort.toLowerCase().includes(lowerSearchText)
      );
    }
  }

  public onSelectedItemChange(item: any): void {
    this.selectedAirline = item.IATACode;
  }

}
