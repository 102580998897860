import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  public baseUrl: string = environment.apiBaseUrl;
  public keyHex: any = CryptoJS.enc.Utf8.parse(`-----BEGIN RSA PUBLIC KEY-----
    MIIBCgKCAQEAz5ES0ThVPFuS9VWC/YOrxOmrRBADlFvphXkPltM9silmhgp7Sy1X
    RVmi3MaCmOmtOAwpNV4C1SIbvD3AXYVsxSUmrGGmnS7sFxwbc4gwOKFOJJamzqnv
    8m5T1MPBDUI5Frku3XBhecBHH/zCxoClS9BGplNI6uq9TrMDHqi9aiT6eXiA75eZ
    3qJJaqZRapQprNrkvEygFjhNNMG1AuiYxpBRefOtallpPNcBDWqz+v6blsOS9de6
    LBqa28ZH1yDSQux5fREDlee4ev7U90CK1s4g2ZB27zPRYeLw/D7wtBVbo37W3Hb1
    wRzLpdG/h098VhBXFg36TibIN0o0SvOEOQIDAQAB
    -----END RSA PUBLIC KEY-----`);

  public checkTicketVoidStatus(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/booking/checkTicketVoidStatus`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public memberVerified(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/member/verified`;

    return this.http.put(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public deleteUser(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/member/delete`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public paymentVoid(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/booking/paymentVoid`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public deactivateRedemptionCode(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/deactivateRedemptionCode`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public sendDealEmailToMember(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/sendDealEmailToMember`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public viewBooking(memberId: number, bookingId: string): Observable<any> {
    const endpoint = `${this.baseUrl}/tickets/${memberId}/${bookingId}`;

    return this.http.get(endpoint).pipe(
      map((result: any) => {
        return {
          ...result,
          data: JSON.parse(
            CryptoJS.DES.decrypt(result.data, this.keyHex, {
              mode: CryptoJS.mode.ECB,
              padding: CryptoJS.pad.Pkcs7
            }).toString(CryptoJS.enc.Utf8)
          )
        };
      }),
      catchError((err: any) => throwError(err))
    );
  }

  public placeQueue(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/placeQueue`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public bookingCancel(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/booking/cancel`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public memberBlock(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/member/status`;

    return this.http.put(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public recentlySignupMembers(): Observable<any> {
    const endpoint = `${this.baseUrl}/member/recentlySignupMembers`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public memberSearch(search: string): Observable<any> {
    const endpoint = `${this.baseUrl}/member/search?text=${search}`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getStatistics(): Observable<any> {
    const endpoint = `${this.baseUrl}/statistic`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public routes(fromCityId: number, region: string, cabinCode: string): Observable<any> {
    const endpoint = `${
      this.baseUrl
    }/routes?fromCityId=${fromCityId}&region=${region.toString()}&cabinCode=${cabinCode}`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public updateRoute(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/updateRoute`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public submitDeals(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/route/months`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllAirlines(): Observable<any> {
    const endpoint = `${this.baseUrl}/getAirlines`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getActiveArrivalCities(): Observable<any> {
    const endpoint = `${this.baseUrl}/getActiveArrivalCities`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getFromAirports(): Observable<any> {
    return this.http.get('../../../assets/activeAirports.json').pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public issueTicket(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/issueTicket`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllCities(): Observable<any> {
    const endpoint = `${this.baseUrl}/getAllCities`;

    return this.http.post(endpoint, {}).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public cityUpdate(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/cityUpdate`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllOneAirRoutes(): Observable<any> {
    const endpoint = `${this.baseUrl}/getAllOneAirRoutes`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllMissingRoutes(): Observable<any> {
    const endpoint = `${this.baseUrl}/missing/route`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public oneAirRouteUpdate(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/oneAirRouteUpdate`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllBookings(): Observable<any> {
    const endpoint = `${this.baseUrl}/tickets`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllHotelBookings(): Observable<any> {
    const endpoint = `${this.baseUrl}/getAllBookings`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public triggerAirTicketScheduler(): Observable<any> {
    const endpoint = `${this.baseUrl}/startOrderIssueScheduler`;
    const data = {};

    return this.http.post(endpoint, data).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getHotelBookingDetail(data: any): Observable<any> {
    const endpoint = `${this.baseUrl}/getBookingDetail`;

    return this.http.post(endpoint, data).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public cancelHotelBookings(data: any): Observable<any> {
    const endpoint = `${this.baseUrl}/cancelBooking`;

    return this.http.post(endpoint, data).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllFlightSearches(): Observable<any> {
    const endpoint = `${this.baseUrl}/flight-searches`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllHotelSearches(): Observable<any> {
    const endpoint = `${this.baseUrl}/hotelSearches`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public bookingAction(data: any, url: string): Observable<any> {
    const endpoint = `${this.baseUrl}/${url}`;
    return this.http.post(endpoint, data).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllAffiliates(): Observable<any> {
    const endpoint = `${this.baseUrl}/getAffiliates`;
    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public updateAffiliateStatus(data: any): Observable<any> {
    const endpoint = `${this.baseUrl}/updateAffiliate`;
    return this.http.post(endpoint, data).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllHotelTrackingList(): Observable<any> {
    const endpoint = `${this.baseUrl}/track/hotelBookings`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllAirlineCommission(): Observable<any> {
    const endpoint = `${this.baseUrl}/getAirlineCommission`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public updateAirlineCommission(data: any): Observable<any> {
    const endpoint = `${this.baseUrl}/upsertAirlineCommission`;

    return this.http.post(endpoint, data).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }
}
