import { ReusableButtonComponent } from './reusable-button/reusable-button.component';
import { ReusableCalendarComponent } from './reusable-calendar/reusable-calendar.component';
import { ReusableConfirmationAlertComponent } from './reusable-confirmation-alert/reusable-confirmation-alert.component';
import { ReusableDropdownComponent } from './reusable-dropdown/reusable-dropdown.component';
import { ReusableInputChipsComponent } from './reusable-input-chips/reusable-input-chips.component';
import { ReusableInputComponent } from './reusable-input/reusable-input.component';
import { ReusableSearchableDropdownComponent } from './reusable-searchable-dropdown/reusable-searchable-dropdown.component';

export const components: any[] = [
  ReusableInputComponent,
  ReusableButtonComponent,
  ReusableCalendarComponent,
  ReusableDropdownComponent,
  ReusableInputChipsComponent,
  ReusableConfirmationAlertComponent,
  ReusableSearchableDropdownComponent
];
