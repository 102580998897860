import * as moment from 'moment';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { Component,  OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'hotel-tracking',
  templateUrl: './hotel-tracking.component.html',
  styleUrls: ['./hotel-tracking.component.scss']
})
export class HotelTrackingComponent implements OnInit {
  constructor(private dashboardService: DashboardService, private _liveAnnouncer: LiveAnnouncer) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public displayedColumns: string[] = [
    'trackHotelBookingRequestId',
    'memberId',
    'hotelId',
    'roomId',
    'hotelName',
    'hotelAddress',
    'city',
    'country',
    'roomType',
    'noOfRooms',
    'noOfAdultsPerRoom',
    'noOfChildPerRoom',
    'mealPlan',
    'price',
    'status',
    'checkInDate',
    'checkOutDate',
    'lastCancellationDate',
    'createDate'
  ];
  public searchControl: FormControl = new FormControl();
  public dataSource = new MatTableDataSource<any>([]);
  public loading: boolean = true;
  public hotelTrackingList: any[] = [];
  public showBookingDetails: boolean = false;
  public bookingDetails: any = {};

  ngOnInit(): void {
    this.getAllHotelTrackingList();
  }

  public getAllHotelTrackingList(): void {
    this.dashboardService.getAllHotelTrackingList().subscribe((res: any) => {
      this.hotelTrackingList = res.data.map((d: any) => {
        return {
          ...d,
          // emailId: d.member.emailId
        }
      });
      this.dataSource = new MatTableDataSource<any>(this.hotelTrackingList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    },
    (err: any) => {
      this.loading = false;
    });
  }

  public searchItem(): void {
    this.searchControl.setValue(this.searchControl.value.trim())
    const query = this.searchControl.value.toLowerCase();
    const result = this.hotelTrackingList.filter((data: any) => {
      return data.trackHotelBookingRequestId?.toString().search(query) !== -1 || data.memberId?.toString().search(query) !== -1 || (data.hotelId && data.hotelId?.toString().search(query) !== -1)  || (data.roomId && data.roomId?.toString().search(query) !== -1) || data.hotelName?.toLowerCase().search(query) !== -1;
    });
    this.dataSource = new MatTableDataSource<any>(result);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public resetSearch(event: string): void {
    if (!event) {
      const result = this.hotelTrackingList.filter((data: any) => {
        return data.trackHotelBookingRequestId.toString().toLowerCase().search('') !== -1 || data.memberId.toString().toLowerCase().search('') !== -1 || data.hotelId.toString().toLowerCase().search('') !== -1 || data.roomId.toString().toLowerCase().search('') !== -1 || data.hotelName.toLowerCase().search('') !== -1;
      });
      this.dataSource = new MatTableDataSource<any>(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  public getMomentDate(date: string): string {
    return moment(date).format('MMMM DD, YYYY');
  }

  public getMomentDateTime(date: string): string {
    return moment(date).format('MMM DD, YYYY hh:mm A');
  }

  public announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}
