import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'reusable-searchable-dropdown',
  templateUrl: './reusable-searchable-dropdown.component.html',
  styleUrls: ['./reusable-searchable-dropdown.component.scss']
})
export class ReusableSearchableDropdownComponent {
  @Input() selectedItem: any = null;
  @Input() airlines: any[] = [];
  @Output() selectedItemChange = new EventEmitter<any>();

  public searchText: string = '';
  public filteredAirlines: any[] = [...this.airlines];

  // Method to filter airlines based on search input
  filterItems(): void {
    const lowerSearchText = this.searchText.toLowerCase();
    this.filteredAirlines = this.airlines.filter(item =>
      item.IATACode.toLowerCase().includes(lowerSearchText)
    );
  }

  // Method to handle the item selection and emit the selected item to the parent
  onSelectItem(item: any): void {
    this.selectedItemChange.emit(item);  // Emit the selected item
  }
}
